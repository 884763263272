import { buildTheme } from '@codinpad/shared-components/common/themeProvider/themes.js'
import { StyledEngineProvider, Theme, ThemeProvider as MUIThemeProvider } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

declare module '@mui/material/styles' {
  interface Palette {
    activation: Palette['primary']
    question: {
      favorite: string
      example: string
      takeHome: string
      live: string
    }
    scrollbar: {
      color: string
      hover: string
      active: string
    }
    accordion: {
      active: string
      inactive: string
      details: {
        background: string
      }
    }
    resizer: {
      background: string
      active: string
    }
    chatHistory: {
      queryBackground: string
      responseBackground: string
    }
    editor: {
      footer: string
      sidebar: {
        active: string
        background: string
        hover: string
      }
      titleBar: {
        userIndicatorBackground: string
        userIndicatorForeground: string
      }
      iconStroke: string
    }
    filePane: {
      activeBackground: string
      activeText: string
      background: string
      folderHighlightDepth: string
      folderSubHighlightDepth: string
      folderHighlightDepthOpaque: string
      folderSubHighlightDepthOpaque: string
      highlightBackground: string
      inputBackground: string
      inputText: string
    }
    environmentSelector: {
      searchField: {
        background: string
        outline: string
        hover: string
      }
      chip: {
        beta: {
          background: string
          color: string
        }
        new: {
          background: string
          color: string
        }
      }
    }
    padSwitch: {
      thumb: {
        color: string
      }
      track: {
        color: string
      }
    }
    programOutput: {
      tabs: {
        text: string
        background: string
        border: string
        selectedText: string
        selectedBorder: string
        unseen: string
      }
      console: {
        background: string
        border: string
        buttonBackground: string
        buttonColor: string
        buttonBorder: string
      }
      iFrame: {
        background: string
      }
    }
    playback: {
      background: string
      border: string
      timeline: {
        background: {
          primary: string
          user: string
        }
        ticks: {
          edit: string
          idle: string
          run: string
          paste: string
          aiChat: string
          focus: string
        }
      }
      adSpace: {
        title: string
        body: string
        background: string
        border: string
        button: {
          border: string
          background: string
          active: string
        }
      }
      adSpaceHighContrast: {
        title: string
        body: string
        background: string
        border: string
        button: {
          border: string
          background: string
          active: string
        }
      }
      execution: {
        header: {
          color: string
        }
        log: {
          previous: {
            color: string
          }
          current: {
            color: string
          }
        }
      }
    }
    tabNavHoverMenu: {
      backgroundColor: string
      questionTitle: {
        backgroundColor: string
      }
      questionSubtext: {
        color: string
      }
      collapseMenu: {
        backgroundColor: string
      }
    }
    footer: {
      backgroundColor: string
    }
    insights: {
      blue: string
      green: string
      palegreen: string
      yellow: string
      orange: string
      red: string
      pink: string
      purple: string
      grey: string
    }
    brandInterview: PaletteColor
    brandScreen: PaletteColor
    annualPlans: {
      selectedToggle: string
      unselectedToggle: string
      selectedAnnually: string
      selectedMonthly: string
      recommendedBg: string
    }
    pricing: {
      bg: string
      lightGrey: string
      sandboxBg: string
      sandboxButton: string
    }
    drawing: {
      dark: {
        bg: string
        toolbarBg: string
        toolbarColor: string
        toolbarColorDisabled: string
        modeSelected: string
        modeHovered: string
      }
      light: {
        bg: string
        toolbarBg: string
        toolbarColor: string
        toolbarColorDisabled: string
        modeSelected: string
        modeHovered: string
      }
    }
    quotaWarning: PaletteColor
    backgroundContrast: {
      50: string
    }
    languageSelector: {
      categoryFilter: {
        bg: string
        active: string
      }
    }
  }

  interface PaletteOptions {
    activation: Palette['primary']
    customColor?: {
      [key: string]: string
    }
    question?: {
      favorite: string
      example: string
      takeHome: string
      live: string
    }
    scrollbar?: {
      color: string
      hover: string
      active: string
    }
    resizer: {
      background: string
      active: string
    }
    chatHistory: {
      queryBackground: string
      responseBackground: string
    }
    editor?: {
      footer: string
      sidebar: {
        active: string
        background: string
        hover: string
      }
      titleBar: {
        userIndicatorBackground: string
        userIndicatorForeground: string
      }
      iconStroke: string
    }
    filePane?: {
      activeBackground: string
      activeText: string
      background: string
      folderHighlightDepth: string
      folderSubHighlightDepth: string
      folderHighlightDepthOpaque: string
      folderSubHighlightDepthOpaque: string
      highlightBackground: string
      inputBackground: string
      inputText: string
    }
    environmentSelector: {
      searchField: {
        background: string
        outline: string
        hover: string
      }
      chip: {
        beta: {
          background: string
          color: string
        }
        new: {
          background: string
          color: string
        }
      }
    }
    programOutput?: {
      tabs: {
        text: string
        background: string
        border: string
        selectedText: string
        selectedBorder: string
        unseen: string
      }
      console: {
        background: string
        border: string
        buttonBackground: string
        buttonColor: string
        buttonBorder: string
      }
      iFrame: {
        background: string
      }
    }
    playback?: {
      background: string
      border: string
      timeline: {
        background: {
          primary: string
          user: string
        }
        ticks: {
          edit: string
          idle: string
          run: string
          paste: string
          aiChat: string
        }
        focus: string
      }
      adSpace: {
        title: string
        body: string
        background: string
        border: string
        button: {
          border: string
          background: string
          active: string
        }
      }
      adSpaceHighContrast: {
        title: string
        body: string
        background: string
        border: string
        button: {
          border: string
          background: string
          active: string
        }
      }
      execution: {
        header: {
          color: string
        }
        log: {
          previous: {
            color: string
          }
          current: {
            color: string
          }
        }
      }
    }
    tabNavHoverMenu?: {
      backgroundColor: string
      questionTitle: {
        backgroundColor: string
      }
      questionSubtext: {
        color: string
      }
      collapseMenu: {
        backgroundColor: string
      }
    }
    footer?: {
      backgroundColor: string
    }
    insights?: {
      blue: string
      green: string
      palegreen: string
      yellow: string
      orange: string
      red: string
      pink: string
      purple: string
      grey: string
    }
    brandInterview?: PaletteColor
    brandScreen?: PaletteColor
    annualPlans?: {
      selectedToggle: string
      unselectedToggle: string
      selectedAnnually: string
      selectedMonthly: string
      recommendedBg: string
    }
    pricing?: {
      bg: string
      lightGrey: string
      sandboxBg: string
      sandboxButton: string
    }
    drawing?: {
      dark: {
        bg: string
        toolbarBg: string
        toolbarColor: string
        toolbarColorDisabled: string
        modeSelected: string
        modeHovered: string
      }
      light: {
        bg: string
        toolbarBg: string
        toolbarColor: string
        toolbarColorDisabled: string
        modeSelected: string
        modeHovered: string
      }
    }
    quotaWarning?: PaletteColor
    backgroundContrast: {
      50: string
    }
    languageSelector: {
      categoryFilter: {
        bg: string
        active: string
      }
    }
  }
}

export enum ThemeMode {
  Dark = 'DARK',
  Light = 'LIGHT',
}

const themeContext = createContext({
  mode: ThemeMode.Dark,
  toggleMode: () => {},
})

export const getTheme = (mode?: ThemeMode): Theme => {
  const isDark = mode === ThemeMode.Dark
  const primary = {
    [300]: '#1A44BC',
    main: '#2D72CC',
  }
  const muiTheme = buildTheme('coderPad', isDark ? 'dark' : 'light', {
    typography: {
      htmlFontSize: 16,
      fontSize: 14,
      button: {
        textTransform: 'capitalize',
      },
      body2: {
        fontSize: '0.75rem',
      },
      h1: {
        fontSize: '2rem',
        fontWeight: 300,
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 500,
        // This is make sure the Rails styles for h2s don't override the MUI styles.
        color: 'inherit',
      },
      h3: {
        fontSize: '0.875rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      h4: {
        fontSize: '0.875rem',
        fontWeight: 700,
        color: 'inherit',
      },
      subtitle1: {
        color: isDark ? '#E0E0E0' : '#4F5053',
      },
    },
    palette: {
      mode: isDark ? 'dark' : 'light',
      activation: {
        light: '#26dbb8',
        // main: '#37C773',
        main: '#32ceaf',
        dark: '#3daf8e',
        contrastText: '#fff',
      },
      customColor: {}, // DO NOT DELETE: used in shared-components to add custom colors
      question: {
        favorite: '#fff176',
        example: '#ffe0b2',
        takeHome: deepPurple[200],
        live: '#f8bbd0',
      },
      brandInterview: {
        contrastText: '#fff',
        dark: '#862F26',
        light: '#E38E84',
        main: '#CC4E41',
      },
      brandScreen: {
        contrastText: '#353C4E',
        dark: '#EEAC0E',
        light: '#F6CF5A',
        main: '#F2BB13',
      },
      error: {
        main: '#e74c3c',
        contrastText: '#fff',
      },
      quotaWarning: {
        contrastText: '#fff',
        dark: '#74097c',
        light: '#e266eb',
        main: '#be1cca',
      },
      insights: {
        blue: '#4D87D4',
        green: '#52DB8C',
        palegreen: '#8ADFCF',
        yellow: '#F6CF5A',
        orange: '#FF9977',
        red: '#FF526D',
        pink: '#EB73FF',
        purple: '#C299FF',
        grey: '#D4D4D4',
      },
      annualPlans: {
        selectedToggle: '#4F5053',
        unselectedToggle: '#848587',
        selectedAnnually: '#15BA48',
        selectedMonthly: '#bababa',
        recommendedBg: '#eaeaea',
      },
      pricing: {
        bg: '#f5f4f4',
        lightGrey: '#cacbcb',
        sandboxBg: '#ececec',
        sandboxButton: '#363739',
      },
      drawing: {
        dark: {
          bg: '#1E2126',
          toolbarBg: '#282C32',
          toolbarColor: '#FFFFFF',
          toolbarColorDisabled: '#777D8C',
          modeSelected: '#696A6D',
          modeHovered: '#3D434D',
        },
        light: {
          bg: '#FFFFFF',
          toolbarBg: '#E8ECF2',
          toolbarColor: '#262729',
          toolbarColorDisabled: '#A7A8A9',
          modeSelected: '#DBE3FA',
          modeHovered: '#D0D4D9',
        },
      },
      ...(isDark
        ? {
            scrollbar: {
              color: 'rgba(255, 255, 255, 0.2)',
              hover: 'rgba(255, 255, 255, 0.4)',
              active: 'rgba(255, 255, 255, 0.6)',
            },
            accordion: {
              active: '#34D578',
              inactive: '#A7A8A9',
              details: {
                background: '#282C32',
              },
            },
            resizer: {
              background: '#444950',
              active: '#4D87D4',
            },
            chatHistory: {
              queryBackground: '#1E2126',
              responseBackground: '#171a1e',
            },
            editor: {
              footer: '#282C32',
              sidebar: {
                active: '#292B30',
                background: '#313439',
                hover: '#2C2E32',
              },
              titleBar: {
                userIndicatorBackground: '#313439',
                userIndicatorForeground: '#BDBDBD',
              },
              iconStroke: '#BDBDBD',
            },
            filePane: {
              activeBackground: '#22282E',
              activeText: '#FFF',
              background: '#313439',
              folderHighlightDepth: '#828282',
              folderSubHighlightDepth: '#626262',
              folderHighlightDepthOpaque: '#82828251',
              folderSubHighlightDepthOpaque: '#62626251',
              highlightBackground: '#22252AAF',
              inputBackground: '#1E2126',
              inputText: '#FFFFFF',
            },
            environmentSelector: {
              searchField: {
                background: '#1E2126',
                outline: '#436BE5',
                hover: '#313439',
              },
              chip: {
                beta: {
                  background: '#1E4FE1',
                  color: '#FFFFFF',
                },
                new: {
                  background: '#26D968',
                  color: '#262729',
                },
              },
            },
            padSwitch: {
              thumb: {
                color: '#34D578',
              },
              track: {
                color: '#C2F2D7',
              },
            },
            programOutput: {
              tabs: {
                text: '#7F7F7F',
                background: '#1D2126',
                border: '#2D353C',
                selectedText: '#FFFFFF',
                selectedBorder: '#FFFFFF',
                unseen: '#0073FF',
              },
              console: {
                background: '#1e2126',
                border: '#22282e',
                buttonBackground: '#1d2126',
                buttonColor: 'white',
                buttonBorder: '#2d353c',
              },
              iFrame: {
                background: '#FDFDFD',
              },
            },
            playback: {
              background: '#282C32',
              border: '#3E4248',
              timeline: {
                background: {
                  primary: '#1D2126',
                  user: '#2C3036',
                },
                ticks: {
                  edit: '#ffffff3b',
                  idle: 'transparent',
                  run: '#F2F2F2',
                  paste: '#F6D669',
                  aiChat: '#ffffff4b',
                  focus: '#e97b3d',
                },
              },
              adSpace: {
                title: '#CACBCB',
                body: '#A7A8A9',
                background: '#282C32',
                border: '#444950',
                button: {
                  border: '#696A6D',
                  background: '#444950',
                  active: '#282C32',
                },
              },
              adSpaceHighContrast: {
                title: '#1B1E22',
                body: '#1B1E22',
                background: '#CACBCB',
                border: '#CACBCB',
                button: {
                  border: '#A7A8A9',
                  background: '#EAEAEA',
                  active: '#444950',
                },
              },
              execution: {
                header: {
                  color: 'inherit',
                },
                log: {
                  previous: {
                    color: '#ADB7C8',
                  },
                  current: {
                    color: '#fff',
                  },
                },
              },
            },
            tabNavHoverMenu: {
              backgroundColor: '#282C32',
              questionTitle: {
                backgroundColor: '#3F434B',
              },
              questionSubtext: {
                color: '#3F434B',
              },
              collapseMenu: {
                backgroundColor: '#313439',
              },
            },
            footer: {
              backgroundColor: '#22282e',
            },
            secondary: {
              contrastText: '#353C4E',
              main: '#E0E0E0',
            },
            languageSelector: {
              categoryFilter: {
                bg: '#24282E',
                active: '#3F434B',
              },
            },
          }
        : {
            text: {
              primary: '#353C4E',
            },
            secondary: {
              main: '#353C4E',
              contrastText: '#fff',
            },
            scrollbar: {
              color: 'rgba(0, 0, 0, 0.2)',
              hover: 'rgba(0, 0, 0, 0.4)',
              active: 'rgba(0, 0, 0, 0.6)',
            },
            accordion: {
              active: '#000000',
              inactive: '#4F5053',
              details: {
                background: '#F7F7F8',
              },
            },
            resizer: {
              background: '#CACBCB',
              active: '#4D87D4',
            },
            chatHistory: {
              queryBackground: '#ECECEC',
              responseBackground: '#F3F3F3',
            },
            editor: {
              footer: '#E6E6E7',
              sidebar: {
                active: '#DADADA',
                background: '#EDEDED',
                hover: '#E6E6E6',
              },
              titleBar: {
                userIndicatorBackground: '#EDEDED',
                userIndicatorForeground: '#21252a',
              },
              iconStroke: '#34495E',
            },
            filePane: {
              activeBackground: '#DADADA',
              activeText: '#21252A',
              background: '#EDEDED',
              folderHighlightDepth: '#626262',
              folderSubHighlightDepth: '#828282',
              folderHighlightDepthOpaque: '#62626251',
              folderSubHighlightDepthOpaque: '#82828251',
              highlightBackground: '#E5E5E5',
              inputBackground: '#DADADA',
              inputText: '#828282',
            },
            environmentSelector: {
              searchField: {
                background: '#E5E5E5',
                outline: '#436BE5',
                hover: '#cccccc',
              },
              chip: {
                beta: {
                  background: '#1E4FE1',
                  color: '#FFFFFF',
                },
                new: {
                  background: '#26D968',
                  color: '#262729',
                },
              },
            },
            padSwitch: {
              thumb: {
                color: '#34D578',
              },
              track: {
                color: '#65C466',
              },
            },
            programOutput: {
              tabs: {
                text: '#8F8F91',
                background: '#FAFAFA',
                border: '#8F8F91',
                selectedText: '#34495E',
                selectedBorder: '#34495E',
                unseen: '#428BCA',
              },
              console: {
                background: '#fafafa',
                border: '#eaeaeb',
                buttonBackground: '#fafafa',
                buttonColor: '34495e',
                buttonBorder: '#34495e',
              },
              iFrame: {
                background: '#EDEDED',
              },
            },
            playback: {
              background: '#E6E6E7',
              border: '#BDBDBD',
              timeline: {
                background: {
                  primary: '#D8D8D8',
                  user: '#D8D8D8',
                },
                ticks: {
                  edit: '#8080805e',
                  idle: 'transparent',
                  run: '#F2F2F2',
                  paste: '#F6D669',
                  aiChat: '#8080806e',
                  focus: '#e97b3d',
                },
              },
              adSpace: {
                title: '#4F5053',
                body: '#4F5053',
                background: '#FAFAFA',
                border: '#BDBDBD',
                button: {
                  border: '#A7A8A9',
                  background: '#EAEAEA',
                  active: '#444950',
                },
              },
              adSpaceHighContrast: {
                title: '#FFFFFF',
                body: '#FFFFFF',
                background: '#3B3E45',
                border: '#2B2E45',
                button: {
                  border: '#696A6D',
                  background: '#444950',
                  active: '#282C32',
                },
              },
              execution: {
                header: {
                  color: '#232324',
                },
                log: {
                  previous: {
                    color: '#515760',
                  },
                  current: {
                    color: '#232324',
                  },
                },
              },
            },
            tabNavHoverMenu: {
              backgroundColor: '#CACBCB',
              questionTitle: {
                backgroundColor: '#F3F3F3',
              },
              questionSubtext: {
                color: '#3F434B',
              },
              collapseMenu: {
                backgroundColor: '#EAEAEA',
              },
            },
            footer: {
              backgroundColor: '#eaeaeb',
            },
            languageSelector: {
              categoryFilter: {
                bg: '#E5E5E5',
                active: '#CACBCB',
              },
            },
          }),
      background: {
        ...(isDark
          ? {
              [50]: '#1B1E22',
              [100]: '#1E2126',
              [200]: '#26272A',
              [400]: '#282C32',
              [500]: '#313439',
              [800]: '#40464F',
            }
          : {
              [50]: '#FCFDFD',
              [100]: '#F9FAFA',
              [200]: '#F7F7F8',
              [400]: '#ECECEC',
              [500]: '#E5E5E5',
              [800]: '#DADADA',
            }),
      },
      backgroundContrast: {
        ...(isDark
          ? {
              [50]: '#FFF',
            }
          : {
              [50]: '#1B1E22',
            }),
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            maxWidth: '320px',
            padding: '10px 12px',
            borderRadius: '4px',
            fontSize: '0.875rem',
            fontWeight: 400,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: isDark ? '#292B30' : '#DADADA',
              '&:hover': {
                backgroundColor: isDark ? '#292B30' : '#DADADA',
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            ...(isDark ? { color: '#E0E0E0' } : { color: '#34495E' }),
            '&.Mui-selected': {
              color: isDark ? '#E0E0E0' : '#34495E',
            },
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          textColor: 'inherit',
        },
        styleOverrides: {
          indicator: {
            ...(isDark ? { backgroundColor: '#fff' } : { backgroundColor: primary.main }),
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            ...(isDark
              ? { backgroundColor: '#292B30', color: '#E0E0E0' }
              : { backgroundColor: '#fff' }),
            // fontSize: '0.875rem',
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          // Override the default indicator animation to flip.
          iconDirectionAsc: {
            transform: 'scaleY(1)',
          },
          iconDirectionDesc: {
            transform: 'scaleY(-1)',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked:not(.colored)': {
              color: isDark ? undefined : 'inherit',
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            ...(isDark
              ? {
                  '&.MuiToggleButton-primary.Mui-selected': {
                    backgroundColor: primary[300],
                    color: '#fff',
                  },
                }
              : {}),
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          // Wee hack to undo the funky h2 coloration from the Rails styles.
          root: {
            color: 'inherit',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
  })

  return muiTheme
}

export const useTheme = (mode?: ThemeMode) => useMemo(() => getTheme(mode), [mode])

export const ThemeProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isDarkTheme = useSelector((state) => state.editorSettings.darkColorScheme)
  const [mode, setMode] = useState(isDarkTheme ? ThemeMode.Dark : ThemeMode.Light)
  const theme = useTheme(mode)
  const toggleMode = useCallback(() => {
    setMode(mode === ThemeMode.Dark ? ThemeMode.Light : ThemeMode.Dark)
  }, [mode])

  useEffect(() => {
    setMode(isDarkTheme ? ThemeMode.Dark : ThemeMode.Light)
  }, [isDarkTheme])

  return (
    <MUIThemeProvider theme={theme}>
      <themeContext.Provider value={{ mode, toggleMode }}>{children}</themeContext.Provider>
    </MUIThemeProvider>
  )
}

/**
 * Handy HoC to wrap existing components with a MUI ThemeProvider. Mostly useful for 1-off views or things
 * outside of the Dashboard pack that might require MUI.
 */
export function withTheme<P extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<React.PropsWithChildren<P>>
) {
  return function MUIThemedComponent(props: P) {
    const theme = useTheme()

    return (
      <StyledEngineProvider injectFirst>
        <MUIThemeProvider theme={theme}>
          <Component {...(props as P)} />
        </MUIThemeProvider>
      </StyledEngineProvider>
    )
  }
}
